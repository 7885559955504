<template>
  <b-row align-h="center">
    <b-col :sm="cardSize.sm" :md="cardSize.md" :lg="cardSize.lg" :xl="cardSize.xl" class="vld-parent">
      <loading :active.sync="loadingOverlayActive" :can-cancel="false" />

      <b-card class="mb-2 form-card">
        <template #header class="card-header">
          <span class="small-link">
            <a href="/ressources/guide-signature.mp4" target="_blank">Guide Vidéo</a>
            | <a href="/ressources/guide.pdf" target="_blank">Guide PDF</a>
          </span>
          <h4 class="my-2">Signature de la Convention</h4>
        </template>

        <b-card-body>
          <div v-if="!convention">
            <b-spinner label="Chargement..." class="d-block mx-auto" />
          </div>
          <form-wizard
            v-else
            @on-change="onChangeStep"
            :startIndex="2"
            title=""
            subtitle=""
            :nextButtonText="nextButtonText"
            backButtonText="Précédent"
            finishButtonText="Signer"
            stepSize="xs"
            color="#3F51B5"
            ref="formWizard"
          >
            <tab-content title="Informations établissement">
              <b-form>
                <b-row>
                  <b-col sm="12" class="mb-2">
                    <b-form-group label="SIRET">
                        <b-form-input :value="convention.etablissement.siret" placeholder="SIRET*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" class="mb-2">
                    <b-form-group label="Raison Sociale">
                        <b-form-input :value="convention.etablissement.raisonSociale" placeholder="Nom de la société*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" class="mb-2">
                    <b-form-group label="Adresse">
                        <b-form-input :value="convention.etablissement.adresse" placeholder="Adresse*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" class="mb-2">
                    <b-form-group label="Code Postal">
                        <b-form-input :value="convention.etablissement.codePostal" placeholder="Code postal*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" class="mb-2">
                    <b-form-group label="Ville">
                        <b-form-input :value="convention.etablissement.ville" placeholder="Ville*" disabled />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Contact opérationnel" :beforeChange="validateContact">
              <b-form @submit.prevent="submitContact">
                <b-row>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Prénom">
                        <b-form-input class="uppercase-input" v-model="convention.contactOperationnel.prenom" placeholder="Prénom*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Nom">
                        <b-form-input class="uppercase-input" v-model="convention.contactOperationnel.nom" placeholder="Nom*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Qualité">
                        <b-form-input class="uppercase-input" v-model="convention.contactOperationnel.qualite" placeholder="Qualité*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Email">
                        <b-form-input v-model="convention.contactOperationnel.email" type="email" placeholder="Adresse email*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Téléphone Fixe">
                        <b-form-input v-model="convention.contactOperationnel.telFixe" type="tel" placeholder="Téléphone Fixe*" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Téléphone Mobile">
                        <b-form-input v-model="convention.contactOperationnel.telMobile" type="tel" placeholder="Téléphone Mobile*" disabled />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Signataire de la convention" :beforeChange="validateSignatory">
              <b-form @submit.prevent="submitSignatory">
                <b-row>
                  <b-col sm="12" class="mb-2">
                    <div>
                      <b-form-group label="Civilité">
                        <b-form-radio-group
                          v-model="convention.signataire.civilite"
                          :options="[ { text: 'M', value: 'M' }, { text: 'Mme', value: 'MME' } ]"
                          name="civilite-radio"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Prénom">
                        <b-form-input class="uppercase-input" v-model="convention.signataire.prenom" placeholder="Prénom*" required />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Nom">
                        <b-form-input class="uppercase-input" v-model="convention.signataire.nom" placeholder="Nom*" required />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Qualité">
                        <b-form-input class="uppercase-input" v-model="convention.signataire.qualite" placeholder="Qualité*" required />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Adresse email">
                    <b-form-input v-model="convention.signataire.email" type="email" placeholder="Adresse email*"   required />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Téléphone">
                        <b-form-input v-model="convention.signataire.tel" type="tel" placeholder="Téléphone*" required />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Pièces justificatives" :beforeChange="validateAttachments">
              <b-form @submit.prevent="submitAttachments">
                <b-row>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Carte Nationale d'Identité (Recto)">
                        <b-form-file
                            v-model="convention.cniRectoFile"
                            accept="image/jpeg, image/png, application/pdf"
                            placeholder="JPG, PNG, PDF - Max 2Mo"
                            drop-placeholder="Déposez un fichier ici..."
                            @input="e => handleFileUpload('cniRectoFile', e)"
                            :state="!!convention.cniRectoFile || !!convention.cniRecto"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Carte Nationale d'Identité (Verso)">
                        <b-form-file
                            v-model="convention.cniVersoFile"
                            accept="image/jpeg, image/png, application/pdf"
                            placeholder="JPG, PNG, PDF - Max 2Mo"
                            drop-placeholder="Déposez un fichier ici..."
                            @input="e => handleFileUpload('cniVersoFile', e)"
                            :state="!!convention.cniVersoFile || !!convention.cniVerso"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-2">
                    <b-form-group label="Attestation employeur">
                        <b-form-file
                            v-model="convention.attestationFile"
                            accept="image/jpeg, image/png, application/pdf"
                            placeholder="JPG, PNG, PDF - Max 2Mo"
                            drop-placeholder="Déposez un fichier ici..."
                            @input="e => handleFileUpload('attestationFile', e)"
                            :state="!!convention.attestationFile || !!convention.attestation"
                        />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Signature" :beforeChange="validateAndSendOtp">
                <vue-pdf-app v-if="showPdf" :config="pdfViewerConfig" ref="pdfViewer" :page-number="pdfPage" class="mb-2" style="height: 80vh" :pdf="`/ressources/convention/convention.pdf`" />

                <b-row>
                  <b-col sm="12" class="my-4">
                    <b-form-group label="Choix de la solution technique :">
                      <b-form-radio-group
                        v-model="convention.solutionTechnique"
                        :options="[ { text: 'SPR', value: 'SPR' }, { text: 'STA', value: 'STA' } ]"
                        name="solution-technique-radio"
                        required
                      />
                    </b-form-group>
                    <i>Il est précisé que les initiales SPR désignent Signification sur Plateforme de Réception et les initiales STA désignent Signification par Traitement
                    Automatique, au sens du présent Accord.</i>
                  </b-col>
                  <b-col sm="12" class="my-2">
                    <strong>Procédures intégrées</strong>
                    <table class="table">
                      <tr>
                        <td>Procédure de saisie-attribution <small>(obligatoire depuis le 1er avril 2021)</small></td>
                        <td><b-form-checkbox :checked="true" :disabled="true" /></td>
                      </tr>
                      <tr>
                        <td>Procédure de saisie conservatoire de créances <small>(obligatoire depuis le 1er avril 2021)</small></td>
                        <td><b-form-checkbox :checked="true" :disabled="true" /></td>
                      </tr>
                      <tr>
                        <td>Procédure de saisie des droits incorporels <small>(facultatif)</small></td>
                        <td><b-form-checkbox v-model="convention.proceduresIntegrees.saisieDroitsIncorporels" /></td>
                      </tr>
                      <tr>
                        <td>Procédure de saisie conservatoire des valeurs mobilières <small>(facultatif)</small></td>
                        <td><b-form-checkbox v-model="convention.proceduresIntegrees.saisieConservatoireValeursMobilieres" /></td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
                <hr>

                <h5 class="mt-2">En cliquant sur « Valider », vous recevrez un code de vérification par email</h5>
            </tab-content>
            <tab-content title="Vérification" :beforeChange="validateOtp">
                <h4 class="text-center">
                    Veuillez saisir le code de sécurité envoyé à l'adresse <strong>{{ convention.signataire.email }}</strong>
                </h4>
                <div class="mx-auto mb-2" style="max-width: 300px;">
                    <input
                        type="text"
                        class="form-control"
                        v-model="otp"
                        placeholder="Code de sécurité"
                        disabled
                    />
                    <CodeKeyboard @pressed="otp = $event" :selfValue="otp" />
                </div>
            </tab-content>

          </form-wizard>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from '@axios'
import VuePdfApp from "vue-pdf-app"
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css"
import CodeKeyboard from "@/components/CodeKeyboard"

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "PreRegistration",
  components: {
    FormWizard,
    TabContent,
    VuePdfApp,
    CodeKeyboard,
    Loading,
  },
  data() {
    return {
        pdfViewerConfig: {
          secondaryToolbar: false,
          toolbar: {
            toolbarViewerRight: {
              presentationMode: true,
              openFile: false,
              print: false,
              download: false,
              viewBookmark: false,
            },
          },
        },
        nextButtonText: "Suivant",
        hash: null,
        otp: "",
        pdfPage: 1,
        showPdf: false,
        convention: null,
        cardSize: {
            sm: 12,
            md: 10,
            lg: 9,
            xl: 8,
        },
        loadingOverlayActive: false,
    }
  },
  mounted() {
      this.$router.onReady(() => {
          this.hash = this.$route.params.hash
          this.fetchConvention()
      })
  },
  methods: {
    setCardSizeSmall() {
      this.cardSize = { sm: 12, md: 10, lg: 9, xl: 8 }
    },
    setCardSizeLarge() {
      this.cardSize = { sm: 12, md: 12, lg: 10, xl: 10 }
    },
    onChangeStep(prev, current) {
      if (current === 4) {
        this.setCardSizeLarge()
        this.showPdf = true
        this.nextButtonText = "Valider"
      } else {
        this.setCardSizeSmall()
        this.showPdf = false
        this.nextButtonText = "Suivant"
      }

      return false
    },
    fetchConvention() {
        axios.get(`/conventions/${this.hash}`)
            .then(({ data }) => {
                console.log(data)
                this.convention = data
                if (this.convention.dateSignaturePmca) {
                  this.$toast.open({ message: 'Convention déjà signée', type: 'error' })
                  this.$router.push({ name: 'login '})
                }
            })
            .catch(() => {
                this.$toast.open({ message: 'Convention introuvable', type: 'error' })
                this.$router.push({ name: 'login '})
            })
    },
    handleFileUpload(file, e) {
        console.log(e)
        if (!e) return

        if (e.size > 2 * 1000000) {
            this.$toast.open({ message: 'Taille max 2Mo', type: 'error' })
            this.convention[file] = null
        } else if (!['application/pdf', 'image/jpeg', 'image/png'].includes(e.type)) {
            this.$toast.open({ message: 'Fichiers PDF, JPG et PNG autorisés uniquement', type: 'error' })
            this.convention[file] = null
        }
    },
    validateContact() {
        return true
    },
    submitContact() {
      if (this.validateContact()) {
        this.$refs.formWizard.nextTab()
      }
    },
    async validateSignatory() {
      let message = null
      if (!this.convention.signataire.civilite) message = "Civilité requise"
      else if (!this.convention.signataire.prenom) message = "Prénom requis"
      else if (!this.convention.signataire.nom) message = "Nom requis"
      else if (!this.convention.signataire.qualite) message = "Qualité requise"
      else if (!this.convention.signataire.email) message = "Email requis"
      else if (!this.convention.signataire.tel) message = "Téléphone requis"

      if (message) {
        this.$toast.open({ message, type: 'error' })
        return false
      } else {
        try {
            const convention = { signataire: this.convention.signataire }
            await axios.put(`/conventions/${this.hash}`, convention)
            return true
        } catch (err) {
            console.error(err)
            return false
        }
      }
    },
    submitSignatory() {
      if (this.validateSignatory()) {
        this.$refs.formWizard.nextTab()
      }
    },
    async validateAttachments() {
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })

        console.log({...this.convention})

        let message = null
        if (!this.convention.cniRectoFile && !this.convention.cniRecto) {
            message = "CNI Recto requise"
        } else if (!this.convention.cniVersoFile && !this.convention.cniVerso) {
            message = "CNI Verso requise"
        } else if (!this.convention.attestationFile && !this.convention.attestation) {
            message = "Attestation employeur requise"
        }

        if (message) {
            this.$toast.open({ message, type: 'error' })
            return false
        }

        try {
            const convention = {}
            if (this.convention.cniRectoFile) {
                convention.cniRecto = await toBase64(this.convention.cniRectoFile)
            }
            if (this.convention.cniVersoFile) {
                convention.cniVerso = await toBase64(this.convention.cniVersoFile)
            }
            if (this.convention.attestationFile) {
                convention.attestation = await toBase64(this.convention.attestationFile)
            }

            await axios.put(`/conventions/${this.hash}`, convention)
            this.$toast.open({ message: 'Les pièces ont bien été téléversées', type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            this.$toast.open({
                message: 'Une erreur est survenue lors du téléversement des pièces',
                type: 'error',
            })
            return false
        }
    },
    submitAttachments() {
      if (this.validateAttachments()) {
        this.$refs.formWizard.nextTab()
      }
    },
    async validateAndSendOtp() {
        if (!this.convention.solutionTechnique) {
          this.$toast.open({
              message: 'Veuillez choisir une solution technique',
              type: 'error',
          })
          return false
        }

        try {
            const convention = {
              solutionTechnique: this.convention.solutionTechnique,
              proceduresIntegrees: {
                saisieDroitsIncorporels: this.convention.proceduresIntegrees.saisieDroitsIncorporels,
                saisieConservatoireValeursMobilieres: this.convention.proceduresIntegrees.saisieConservatoireValeursMobilieres,
              }
            }

            await axios.put(`/conventions/${this.hash}`, convention)
            await axios.get(`/conventions/${this.hash}/otp`)
            this.$toast.open({
                message: 'Votre code vous a été envoyé par email',
                type: 'success',
            })
            return true;
        } catch(err) {
            console.error(err);
            return false;
        }
    },
    async validateOtp() {
        this.loadingOverlayActive = true;
        try {
            await axios.post(`/conventions/${this.hash}/otp`, { otp: this.otp })
            this.$toast.open({
                message: 'La convention a bien été signée. Merci !',
                type: 'success',
                duration: 10000,
            })
            this.$router.push({ name: 'signatureTerminee' })
            this.loadingOverlayActive = false;
            return true;
        } catch(err) {
            console.error(err);
            this.loadingOverlayActive = false;
            return false;
        }
    },
  }
}
</script>

<style lang="scss">
.custom-control-inline.custom-radio {
  display: inline-block;
  margin-right: 16px;

  input[type=radio] {
    margin-right: 4px !important;
  }
}
.custom-file-input ~ .custom-file-label::after {
    content: "Parcourir" !important;
}
</style>