<template>
  <b-row align-h="center">
    <b-col sm="12" md="10" lg="8" xl="6">
      <b-card class="mb-2 form-card">
        <template #header class="card-header">
          <span class="small-link">
            <a href="/ressources/guide-preinscription.mp4" target="_blank">Guide Vidéo</a>
            | <a href="/ressources/guide.pdf" target="_blank">Guide PDF</a>
          </span>
          <h4 class="my-2">Connexion</h4>
        </template>

        <b-card-body>

          <!-- Login form -->
          <b-form v-if="step === 'login'" @submit.prevent="login">
            <b-form-group label="Adresse email :" class="mb-3">
              <b-form-input
                v-model="form.email"
                type="email"
                placeholder="jean.dupont@example.org"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Code activation portail :" class="mb-3">
              <b-form-input
                v-model="form.codePortail"
                type="password"
                placeholder="XXXXX"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox class="mb-2" name="cgu" required>
              J'accepte les <router-link :to="{name: 'termsAndConditions'}">Conditions Générales d'Utilisation</router-link>
            </b-form-checkbox>

            <b-button type="submit" variant="primary">Connexion</b-button>
          </b-form>

          <!-- OTP -->
          <b-form v-else-if="step === 'otp'" @submit.prevent="validateOtp">
            <h4 class="text-center">
              Veuillez saisir le code de sécurité envoyé à l'adresse <strong>{{ form.email }}</strong>
            </h4>
            <div class="mx-auto mb-2" style="max-width: 300px;">
              <input
                type="text"
                class="form-control"
                v-model="form.otp"
                placeholder="Code de sécurité"
                disabled
              />
              <CodeKeyboard @pressed="form.otp = $event" :selfValue="form.otp" />
            </div>
            <b-button type="submit" variant="primary" class="d-block mt-4 mx-auto">Valider</b-button>
          </b-form>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import CodeKeyboard from "@/components/CodeKeyboard"
import axios from "@axios"
import useJwt from "@/auth/jwt/useJwt"

export default {
  name: 'Login',
  components: {
    CodeKeyboard,
  },
  data() {
    return {
      form: {
        email: "",
        codePortail: "",
        otp: "",
      },
      step: "login",
    }
  },
  mounted() {
    useJwt.deleteToken()
    useJwt.deleteRefreshToken()
  },
  methods: {
    login() {
      axios
        .post('/auth/login', { email: this.form.email, codePortail: this.form.codePortail })
        .then(() => {
          this.step = "otp";
          this.$toast.open({
            message: 'Votre code vous a été envoyé par email',
            type: 'success',
          })
        })
        .catch(err => {
          console.error(err);
        })
    },
    validateOtp() {
      axios
        .post('/auth/otp', { email: this.form.email, otp: this.form.otp })
        .then(({ data }) => {
          console.log(data);
          useJwt.setToken(data.tokens.access.token)
          useJwt.setRefreshToken(data.tokens.refresh.token)
          this.$router.push({ name: 'preRegistration' })
        })
        .catch(err => {
          console.error(err);
        })
    },
  },
}
</script>