<template>
  <b-row align-h="center">
    <b-col sm="12" md="10" lg="8" xl="6">
      <b-card class="mb-2 form-card">
        <template #header class="card-header">
          <h4 class="my-2">Convention signée</h4>
        </template>
        <b-card-body>
           <h2>Nous vous remercions pour la signature de l'accord.</h2>
            La CHAMBRE NATIONALE DES COMMISSAIRES DE JUSTICE va maintenant en vérifier la conformité, si tout est correct, elle vous transmettra un exemplaire de l'accord signé par les deux parties prenantes.
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Signed',
}
</script>