<template>
  <b-row align-h="center">
    <b-col :sm="cardSize.sm" :md="cardSize.md" :lg="cardSize.lg" :xl="cardSize.xl">
      <b-card class="mb-2 form-card">
        <template #header class="card-header">
          <span class="small-link">
            <a href="/ressources/guide-preinscription.mp4" target="_blank">Guide Vidéo</a>
            | <a href="/ressources/guide.pdf" target="_blank">Guide PDF</a>
          </span>
          <h4 class="my-2">Pré-enregistrement</h4>
        </template>

        <b-card-body>
          <form-wizard
            @on-complete="onComplete"
            @on-change="onChangeStep"
            title=""
            subtitle=""
            nextButtonText="Suivant"
            backButtonText="Précédent"
            finishButtonText="Demande de signature"
            stepSize="xs"
            color="#3F51B5"
            ref="formWizard"
          >
            <tab-content title="Téléchargement de l’accord">
              <div class="mx-auto text-center">
                <b-button v-if="downloadLoading" variant="primary" disabled>
                  <b-spinner small />
                  Télécharger l’accord et ses annexes
                </b-button>
                <a v-else @click="downloadConvention" href="/ressources/convention/convention-et-annexes.zip" class="btn btn-primary" size="lg" download>
                  Télécharger l’accord et ses annexes
                </a>
              </div>
            </tab-content>
            <tab-content title="Établissement" :beforeChange="validateSiret">
              <b-form @submit.prevent="submitSiret">
                <b-form-input v-model="pm.siret" placeholder="SIRET (14 caractères)*" required />
              </b-form>
            </tab-content>
            <tab-content title="Domiciliation">
              <b-form v-if="pm.idPm">
                <b-row>
                  <b-col sm="12">
                    <b-form-input :value="pm.raisonSociale" placeholder="Nom de la société*" disabled />
                  </b-col>
                  <b-col sm="12">
                    <b-form-input :value="pm.adresse" placeholder="Adresse*" disabled />
                  </b-col>
                  <b-col sm="12">
                    <b-form-input :value="pm.codePostal" placeholder="Code postal*" disabled />
                  </b-col>
                  <b-col sm="12">
                    <b-form-input :value="pm.ville" placeholder="Ville*" disabled />
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Contact opérationnel" :beforeChange="validateContact">
              <b-form @submit.prevent="submitContact">
                <b-row>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.prenom" placeholder="Prénom*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.nom" placeholder="Nom*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.qualite" placeholder="Qualité*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.email" type="email" placeholder="Adresse email*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.telFixe" type="tel" placeholder="Téléphone Fixe*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="contact.telMobile" type="tel" placeholder="Téléphone Mobile*" required autocomplete="VL40NHQszv" />
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Signataire de la convention" :beforeChange="validateSignatory">
              <b-form @submit.prevent="submitSignatory">
                <b-row>
                  <b-col sm="12" class="mb-4">
                    <div>
                      <b-form-group label="Civilité :">
                        <b-form-radio-group
                          v-model="signataire.civilite"
                          :options="[ { text: 'M', value: 'M' }, { text: 'Mme', value: 'MME' } ]"
                          name="civilite-radio"
                        />
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="signataire.prenom" placeholder="Prénom*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="signataire.nom" placeholder="Nom*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="signataire.qualite" placeholder="Qualité*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="signataire.email" type="email" placeholder="Adresse email*" required autocomplete="VL40NHQszv" />
                  </b-col>
                  <b-col sm="12" lg="6" class="mb-4">
                    <b-form-input v-model="signataire.tel" type="tel" placeholder="Téléphone*" required autocomplete="VL40NHQszv" />
                  </b-col>
                </b-row>
              </b-form>
            </tab-content>
            <tab-content title="Récapitulatif">

              <h4>Établissement</h4>

              <b-table
                  striped
                  hover
                  :fields="[ { key: 'field' }, { key: 'value' } ]"
                  :items="[
                      { field: 'SIRET', value: pm.siret },
                      { field: 'Raison sociale', value: pm.raisonSociale },
                      { field: 'Adresse', value: pm.adresse.toUpperCase() },
                      { field: 'Code postal', value: pm.codePostal },
                      { field: 'Ville', value: pm.ville.toUpperCase() },
                  ]"
                  thead-class="hidden_header"
              />

              <h4>Contact opérationnel</h4>

              <b-table
                  striped
                  hover
                  :fields="[ { key: 'field' }, { key: 'value' } ]"
                  :items="[
                      { field: 'Prénom', value: contact.prenom.toUpperCase() },
                      { field: 'Nom', value: contact.nom.toUpperCase() },
                      { field: 'Qualité', value: contact.qualite.toUpperCase() },
                      { field: 'Email', value: contact.email.toLowerCase() },
                      { field: 'Tel. Fixe', value: contact.telFixe },
                      { field: 'Tel. Mobile', value: contact.telMobile },
                  ]"
                  thead-class="hidden_header"
              />

              <h4>Signataire</h4>

              <b-table
                  striped
                  hover
                  :fields="[ { key: 'field' }, { key: 'value' } ]"
                  :items="[
                      { field: 'Civilité', value: signataire.civilite },
                      { field: 'Prénom', value: signataire.prenom.toUpperCase() },
                      { field: 'Nom', value: signataire.nom.toUpperCase() },
                      { field: 'Qualité', value: signataire.qualite.toUpperCase() },
                      { field: 'Email', value: signataire.email.toLowerCase() },
                      { field: 'Téléphone', value: signataire.tel },
                  ]"
                  thead-class="hidden_header"
              />

            </tab-content>
          </form-wizard>
          <p v-if="showFinalStepSubtext" class="text-center">
            <small>La demande de signature permet de solliciter le signataire désigné pour signature électronique de l’accord</small>
          </p>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import axios from '@axios';

export default {
  name: "PreRegistration",
  components: {
    FormWizard,
    TabContent,
  },
  data() {
    return {
      docPreview: 'convention',
      downloadLoading: false,
      showFinalStepSubtext: false,
      cardSize: {
        sm: 12,
        md: 10,
        lg: 8,
        xl: 6,
      },
      idTiers: null,
      pm: {
        id: null,
        siret: "",
        raisonSociale: "",
        adresse: "",
        codePostal: "",
        ville: ""
      },
      contact: {
        prenom: "",
        nom: "",
        qualite: "",
        email: "",
        telFixe: "",
        telMobile: "",
      },
      signataire: {
        civilite: "",
        prenom: "",
        nom: "",
        qualite: "",
        email: "",
        tel: "",
      },
      loadingOverlayActive: false,
    };
  },
  methods: {
    downloadConvention() {
      this.downloadLoading = true
      setTimeout(() => {
        this.downloadLoading = false
      }, 2000)
    },
    async onComplete() {
      try {
        const convention = {
          idTiers: this.idTiers,
          etablissement: this.pm,
          contactOperationnel: this.contact,
          signataire: this.signataire,
        }
        const { data } = await axios.post(`/conventions`, convention)
        if (data.id) {
          this.$toast.open({
            message: 'Merci ! Les instructions de signature ont été transmises par email au signataire',
            type: 'success',
            duration: 10000,
          })
          this.$router.push({ name: 'login' })
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.error(err)
        return false;
      }
    },
    onChangeStep(prev, next) {
      if (next === 5) {
        this.showFinalStepSubtext = true;
      } else {
        this.showFinalStepSubtext = false;
      }
    },
    async validateSiret() {
      let message = null;
      if (!this.pm.siret) message = "SIRET requis"
      else if (this.pm.siret.length !== 14) message = "Le SIRET doit être composé de 14 caractères"

      if (message) {
        this.$toast.open({ message, type: 'error' });
        return false;
      }

      try {
        const { data } = await axios.get(`/api-rnc/recherche-pm?siret=${this.pm.siret}`)
        if (!Array.isArray(data) || !data.length || !data[0]) {
            this.$toast.open({
              message: 'SIRET introuvable',
              type: 'error',
            })
            return false;
        }
          
        const pm = data[0];

        if (pm.idTiers) {
          // Tiers déjà existant dans le RNC
          this.idTiers = pm.idTiers;
        }

        this.pm = {
          idPm: pm.id,
          siret: pm.siret,
          raisonSociale: `${pm.raisonSociale.nom1} ${pm.raisonSociale.nom2 || ''}`,
          adresse: `${pm.adresse.ligne1} ${pm.adresse.ligne2 || ''} ${pm.adresse.ligne3 || ''}`,
          codePostal: pm.adresse.codePostal || "",
          ville: pm.adresse.ville || "",
        };
        
        return true;
      } catch (err) {
        console.error(err)
        return false;
      }
    },
    submitSiret() {
    },
    validateContact() {
      let message = null;
      if (!this.contact.prenom) message = "Prénom requis";
      else if (!this.contact.nom) message = "Nom requis";
      else if (!this.contact.qualite) message = "Qualité requise";
      else if (!this.contact.email) message = "Email requis";
      else if (!this.contact.telFixe) message = "Téléphone fixe requis";
      else if (!this.contact.telMobile) message = "Téléphone mobile requis";

      if (message) {
        this.$toast.open({ message, type: 'error' });
        return false;
      } else {
        return true;
      }
    },
    submitContact() {
      if (this.validateContact()) {
        this.$refs.formWizard.nextTab();
      }
    },
    validateSignatory() {
      let message = null;
      if (!this.signataire.civilite) message = "Civilité requise";
      else if (!this.signataire.prenom) message = "Prénom requis";
      else if (!this.signataire.nom) message = "Nom requis";
      else if (!this.signataire.qualite) message = "Qualité requise";
      else if (!this.signataire.email) message = "Email requis";
      else if (!this.signataire.tel) message = "Téléphone requis";

      if (message) {
        this.$toast.open({ message, type: 'error' });
        return false;
      } else {
        return true;
      }
    },
    submitSignatory() {
      if (this.validateSignatory()) {
        this.$refs.formWizard.nextTab();
      }
    },

  }
};
</script>

<style lang="scss">
.custom-control-inline.custom-radio {
  display: inline-block;
  margin-right: 16px;

  input[type=radio] {
    margin-right: 4px !important;
  }
}

.doc-preview {
  margin-right: 12px;
  &.active {
    text-decoration: underline;
    color: #000;
    font-weight: bolder;
  }
}

.hidden_header {
  display: none;
}
</style>